import React, { useEffect, useState } from "react";
import $ from "jquery";
import WOW from "wowjs";
import News from "../../Shared/News";
import { Helmet } from "react-helmet-async";
import { Icon } from "@iconify/react";

const Photography = (props) => {
  const [isACtive, setisACtive] = useState(0);

  const handleTabClick = (i) => {
    setisACtive(i);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  $(".projectSectiontabs li").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

  const photographyLdJson = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "BreadcrumbList",
        "@id": "https://www.reversethought.com/site2023/still-photography/#breadcrumblist",
        "itemListElement": [
          {
            "@type": "ListItem",
            "@id": "https://www.reversethought.com/site2023/#listItem",
            "position": 1,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.reversethought.com/site2023/",
              "name": "Home",
              "description": "Reverse Thought is a multi-disciplinary digital creative agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more. Contact Now",
              "url": "https://www.reversethought.com/site2023/"
            },
            "nextItem": "https://www.reversethought.com/site2023/still-photography/#listItem"
          },
          {
            "@type": "ListItem",
            "@id": "https://www.reversethought.com/site2023/still-photography/#listItem",
            "position": 2,
            "item": {
              "@type": "WebPage",
              "@id": "https://www.reversethought.com/site2023/still-photography/",
              "name": "Still Photography",
              "description": "We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography.Visit us to avail our services.",
              "url": "https://www.reversethought.com/site2023/still-photography/"
            },
            "previousItem": "https://www.reversethought.com/site2023/#listItem"
          }
        ]
      },
      {
        "@type": "Organization",
        "@id": "https://www.reversethought.com/site2023/#organization",
        "name": "Reverse Thought Creative Studio Pvt. Ltd.",
        "url": "https://www.reversethought.com/site2023/",
        "sameAs": [
          "https://www.instagram.com/reversethought/",
          "https://www.pinterest.com/reversethoughtcreativestudio/",
          "https://in.linkedin.com/in/tushargarg"
        ]
      },
      {
        "@type": "WebPage",
        "@id": "https://www.reversethought.com/site2023/still-photography/#webpage",
        "url": "https://www.reversethought.com/site2023/still-photography/",
        "name": "Still Photography Services in India | Reverse Thought",
        "description": "We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography.Visit us to avail our services.",
        "inLanguage": "en-US",
        "isPartOf": {
          "@id": "https://www.reversethought.com/site2023/#website"
        },
        "breadcrumb": {
          "@id": "https://www.reversethought.com/site2023/still-photography/#breadcrumblist"
        },
        "datePublished": "2018-12-26T08:39:37+05:30",
        "dateModified": "2023-07-12T12:57:24+05:30"
      },
      {
        "@type": "WebSite",
        "@id": "https://www.reversethought.com/site2023/#website",
        "url": "https://www.reversethought.com/site2023/",
        "name": "Reverse Thought",
        "description": "Best Digital & Creative Agency In Mumbai, India | International Design Agency",
        "inLanguage": "en-US",
        "publisher": {
          "@id": "https://www.reversethought.com/site2023/#organization"
        }
      }
    ]
  }

  return (
    <>
    <Helmet>
        <title>Still Photography Services in India | Reverse Thought </title>
        <meta name="description" content="We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography.Visit us to avail our services." />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="still photography" />
        <link rel="canonical" href="https://www.reversethought.com/site2023/still-photography/" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Still Photography Services in India" />
        <meta property="og:description" content="We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography. Our mantra is Showcase, Attract &amp; Impress." />
        <meta property="og:url" content="https://www.reversethought.com/site2023/still-photography/" />
        <meta property="article:tag" content="still photography" />
        <meta property="article:published_time" content="2018-12-26T08:39:37+00:00" />
        <meta property="article:modified_time" content="2023-07-12T12:57:24+00:00" />
        <meta property="article:publisher" content="https://www.facebook.com/rtcspl/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
        <meta name="twitter:title" content="Still Photography Services in India" />
        <meta name="twitter:description" content="We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography. Our mantra is Showcase, Attract &amp; Impress." />
        <meta name="google" content="nositelinkssearchbox" />
        <script type="application/ld+json" class="aioseo-schema">{JSON.stringify(photographyLdJson)}</script>
      </Helmet>
      <section className="projectsGallery">
        <div className="content_container">
          <ul className="projectSectiontabs p-0 m-0 d-flex justify-content-between align-items-center wow animate__animated animate__fadeIn">
            <a href="/works/all-projects" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 ">All Project</li>
            </a>
            <a href="/works/website-app" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800">Websites/App</li>
            </a>
            <a
              href="/works/video-production"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800">Video Production</li>
            </a>
            <a
              href="/works/digital-marketing"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800">Digital Marketing</li>
            </a>
            <a
              href="/works/creative-design"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800">Creative Design</li>
            </a>
            <a
              href="/works/digital-activation"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800">Digital Activations</li>
            </a>
            <a href="/works/photography" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 active">Photography</li>
            </a>
          </ul>

          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
              <a
                href="/commercial"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/commercial.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        Commercial
                        <br />
                      </h2>
                      <p className="proj_type text-black">Photography</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/commercial"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryM"></div>

            <div className="projectsGalleryR"></div>
          </div>
        </div>
      </section>
      <section className="weareintheNews customPaddingClass">
        <div className="content_container">
          <News />
        </div>
      </section>
    </>
  );
};

export default Photography;
